<template>
  <v-card :loading="loading" class="home-form-container" color="transparent" dark elevation="0">
    <v-img :src="require('@/assets/images/logoWhite.png')" class="mx-auto" width="45"></v-img>
    <h1 class="access-header h2">Explore Opportunities!</h1>
    <p class="mb-7 text-center">Gradbee connects a community of student designers, developers, artists, researchers to employers</p>
<!--    <social-auth v-if="fbStatus === 'connected'"/>-->
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()" id="register-form" class="v-text-field--rounded form">

      <v-text-field ref="username" autocomplete="new-username" v-model="username" :rules="usernameRules" label="Username" filled required color="white"></v-text-field>

      <v-text-field ref="email" autocomplete="new-email" v-model="email" :rules="emailRules" label="Email" required color="white" filled></v-text-field>

      <v-text-field autocomplete="new-password" v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="showPassword ? 'text' : 'password'" label="Password" filled @click:append="showPassword = !showPassword" required color="white"></v-text-field>

      <v-radio-group v-model="role" row class="mt-0" color="white">
        <v-radio label="Student" value="freelancer" color="white"></v-radio>
        <v-radio label="Employer" value="employer" color="white"></v-radio>
      </v-radio-group>

      <v-btn type="submit" color="pink darken-2" block depressed x-large class="mr-4" :disabled="loading" :loading="loading">
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
        Register
      </v-btn>

      <div class="mt-5 text-center">
        Already have an account? <router-link :to="{name: 'login'}">Log in</router-link>
      </div>

      <v-alert text outlined color="error" icon="mdi-fire" v-if="error" class="mt-5">
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import { onLogin } from '@/vue-apollo.js'
// import SocialAuth from '../../components/SocialAuth'
const REGISTER = require('../../graphql/register.graphql')
export default {
  data: () => ({
    valid: true,
    loading: false,
    username: '',
    usernameRules: [v => !!v || 'Username is required'],
    password: '',
    passwordRules: [v => !!v || 'Password is required'],
    role: 'freelancer',
    email: '',
    emailRules: [v => !!v || 'Email is required'],
    showPassword: false,
    error: null
  }),
  computed: {
    fbStatus () {
      return this.$store.state.fbStatus
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.register()
      }
    },
    async register () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: REGISTER,
          variables: {
            email: this.email,
            username: this.username,
            password: this.password,
            role: this.role
          },
          update: (cache, { data: { register } }) => {
            onLogin(this.$apollo.provider.defaultClient, register.token)
            this.$router.push({ name: 'onboarding' })
          }
        })
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      }
    }
  },
  components: {
    // SocialAuth
  }
}
</script>
<style lang="scss" scoped>
  h1 {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  p {
    letter-spacing: 0.5px;
    font-size: 0.9rem;
  }
</style>
