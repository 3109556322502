<template>
  <v-main class="h-100 d-flex align-center pa-0">
    <v-container>
      <v-row justify="center">
        <v-col :cols="12" :sm="10" :xl="4" :md="6" :lg="5">
          <v-row align="center">
            <v-col><Form class="flex-grow-1"/></v-col>
          </v-row>
        </v-col>
        <v-col :cols="12" :xl="5" :md="6" :lg="7" :sm="10">
          <v-row align="center" class="h-100">
            <v-col><v-img class="pa-5 space" :src="require('../assets/images/home2.png')" width="100%"/></v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- If using vue-router -->
    </v-container>
  </v-main>
</template>

<script>
import Form from '../components/Home/Form'
export default {
  metaInfo () {
    return {
      title: 'Paid Internships, Freelance, Part Time, Summer Training',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Opportunity for students. Find Internships and Freelancing projects. Connect with students all over the world in realtime. Create your Student Profile now!'
      }]
    }
  },
  components: {
    Form
  }
}
</script>

<style scoped lang="scss">
  .space {
    animation: MoveUpDown 1300ms ease-in-out infinite;
  }
</style>
